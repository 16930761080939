<template>
  <div class="zt-page-content">
    <div class="page-header" style="min-width:1320px;">企业授权操作管理</div>

    <div class="zt-block tab flex" style="min-width:1300px;">
      <div class="flex-1"></div>
      <div>
        <el-button @click="initAuth" type="primary" size="small"
          >发起授权</el-button
        >
      </div>
    </div>
    <div class="zt-block" style="min-width:1300px;min-height:600px">
      <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
        <el-table :data="list" style="width: 100%">
          <!-- <el-table-column prop="id" label="企业ID" width="100">
                    </el-table-column> -->
          <el-table-column prop="name" label="企业名称" min-width="200">
            <template slot-scope="scope">
              <div>{{ scope.row.authorizeOrgName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="授权手机号">
            <template slot-scope="scope">
              {{ scope.row.mobilephone }}
            </template>
          </el-table-column>
          <el-table-column prop="cert_stat" label="被授权人员">
            <template slot-scope="scope">
              {{ scope.row.adminName }}
            </template>
          </el-table-column>
          <el-table-column prop="manager" label="状态">
            <template slot-scope="scope">
              {{ statusMap[scope.row.isAuthorize] }}
            </template>
          </el-table-column>
          <el-table-column prop="manager" label="授权起止时间" min-width="180">
            <template slot-scope="scope">
              {{ getAuthTime(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column prop="meter_number" label="操作" min-width="180">
            <template slot-scope="scope">
              <el-button
                v-if="[0, 1].includes(scope.row.isAuthorize)"
                @click="revoke(scope.row)"
                type="text"
                >撤销授权</el-button
              >
              <el-button @click="showLog(scope.row)" type="text"
                >查看日志</el-button
              >
              <el-button
                v-if="scope.row.isAuthorize === 1"
                @click="changeps(scope.row)"
                type="text"
                >修改初始密码</el-button
              >
              <el-button
                v-if="scope.row.isAuthorize === 5"
                @click="login(scope.row)"
                type="default"
                size="small"
                >登录</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding:12px;position:absolute;left:0;bottom:0;">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryOpt.page"
          @current-change="currentChange"
          :page-size="queryOpt.pagesize"
          :total="queryOpt.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 登录 dialog -->
    <el-dialog title="登录" :visible.sync="dialogLoginVisible" width="30%">
      <el-form :model="form" label-position="left" label-width="100px">
        <el-form-item label="账号:">
          <el-input
            type="password"
            style="position:fixed;bottom:-9999px;"
            autocomplete="off"
          ></el-input>
          <el-input
            type="text"
            v-model="loginForm.account"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input
            type="password"
            style="position:fixed;bottom:-9999px;"
            autocomplete="off"
          ></el-input>
          <el-input
            type="password"
            v-model="loginForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="dialogLoginVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="sureLogin">登 录</el-button>
      </div>
    </el-dialog>
    <!-- 修改密码 dialog -->
    <el-dialog
      title="修改初始密码"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form :model="form" label-position="left" label-width="100px">
        <el-form-item label="随机初始密码:">
          <el-input
            type="password"
            v-model="form.initPs"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码:">
          <el-input
            type="password"
            v-model="form.ps"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码:">
          <el-input
            type="password"
            v-model="form.newPs"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="sureChangePs">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 撤销授权 dialog -->
    <el-dialog
      title="撤销授权申请"
      :visible.sync="dialogRevokeVisible"
      width="30%"
    >
      <el-form :model="form" label-position="left" label-width="100px">
        <el-form-item label="撤销授权原因:">
          <el-input
            type="textarea"
            :rows="4"
            v-model="form.reason"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="dialogRevokeVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="sureRevoke">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 发起授权 dialog -->
    <el-dialog
      title="发起授权申请"
      :visible.sync="dialogInitVisible"
      width="40%"
      v-if="dialogInitVisible"
    >
      <el-transfer
        filterable
        :filter-method="filterMethod"
        :titles="['全部工厂列表', '已选工厂列表']"
        filter-placeholder="请输入工厂"
        v-model="checkedOrgs"
        :data="orgData"
        :props="{
          key: 'id',
          label: 'name'
        }"
      >
      </el-transfer>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="dialogInitVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="sureAuth">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 日志 dialog -->
    <el-dialog :title="logTitle" :visible.sync="dialogLogVisible" width="50%">
      <el-table :data="gridData">
        <el-table-column property="operationTime" label="时间">
          <template slot-scope="scope">
            {{
              scope.row.operationTime &&
                scope.row.operationTime.replace("T", " ")
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="type"
          label="操作"
          width="200"
        ></el-table-column>
        <el-table-column property="remark" label="备注"></el-table-column>
        <el-table-column property="createdBy" label="操作人"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="logPageSetting.page"
        @current-change="logPageChange"
        :page-size="logPageSetting.pagesize"
        :total="logPageSetting.total"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import {
  authPagingAdmin,
  authAdminAdd,
  authAdminLog,
  updateAuthStatus,
  getOrgList,
  updatePassword,
  adminLogin
} from "@/service/mes";
import store from "@/store";
export default {
  data() {
    const generateData = _ => {
      const data = [];
      const cities = ["上海", "北京", "广州", "深圳", "南京", "西安", "成都"];
      const pinyin = [
        "shanghai",
        "beijing",
        "guangzhou",
        "shenzhen",
        "nanjing",
        "xian",
        "chengdu"
      ];
      cities.forEach((city, index) => {
        data.push({
          label: city,
          key: pinyin[index],
          pinyin: pinyin[index]
        });
      });
      return data;
    };
    return {
      logTitle: "",
      statusMap: {
        "0": "等待授权中",
        "1": "已发送随机密码短信",
        "2": "已撤销授权",
        "4": "已拒绝",
        "7": "授权时间过期",
        "5": "已授权"
      },
      loginUser: {}, //登录的用户信息
      loginForm: {
        account: "",
        password: ""
      },
      gridData: [
        {
          date: "2018",
          handle: "登录",
          remark: "123",
          person: "戴帽子"
        }
      ],
      orgData: generateData(),
      checkedOrgs: [],
      filterMethod(query, item) {
        return item.name.indexOf(query) > -1;
      },
      form: {
        initPs: "",
        ps: "",
        newPs: "",
        reason: ""
      },
      popVisible: false,
      dialogLogVisible: false,
      dialogRevokeVisible: false,
      dialogInitVisible: false,
      dialogLoginVisible: false,
      popTitle: null,
      logPageSetting: {
        page: 1,
        pagesize: 5,
        total: 0
      },
      queryOpt: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      dialogFormVisible: false,
      list: [1, 2],
      comp_count: null,
      handleRow: {}, //操作行数据
      authedOrgData: [] //已授权企业
    };
  },
  async created() {},
  mounted() {
    this.loginUser = store.state.loginUser?.user;
    this.getauthPagingAdmin();
  },
  methods: {
    //获取企业列表
    async getOrgList() {
      const param = {
        adminid: this.loginUser.id, //用户id
        page: 1, //页数
        pagesize: "", //每页记录数
        comp_code: "", // 企业代码
        comp_name: "", // 企业名称
        comp_type: "", // 企业类型 ：1⼯厂  2设计公司  3销售公司 99政府
        cert_stat: "" // 企业认证状态：0未申请认证 1已申请认证 2已通过认证 3未通过认证
      };
      const res = await getOrgList(param);
      const unAuthedOrgData = res.content?.comps.rows; //未授权
      const authedOrgData = res.content?.resultd.map(org => {
        return {
          id: org.authorizeOrgId,
          name: org.authorizeOrgName,
          disabled: true
        };
      }); //已授权
      this.authedOrgData = authedOrgData;
      this.checkedOrgs = authedOrgData.map(org => org.id);
      const orgData = unAuthedOrgData.concat(authedOrgData);
      this.orgData = orgData;
      console.log("获取企业列表", res);
    },
    getAuthTime(row) {
      const start =
        row.authorizeStartTime && row.authorizeStartTime.replace("T", " ");
      const end =
        row.authorizeEndTime && row.authorizeEndTime.replace("T", " ");
      return `${start || ""} / ${end || ""}`;
    },
    //获取admin授权管理分页查询
    async getauthPagingAdmin() {
      const param = {
        search: {
          adminid: this.loginUser.id //用户id
        },
        pageIndex: this.queryOpt.page, // 当前页码
        pageSize: this.queryOpt.pagesize, // 每页条数
        noCount: false,
        orderBy: "",
        countSql: ""
      };
      const res = await authPagingAdmin(param);
      if (res.success) {
        console.log(res);
        this.list = res.content.rows;
        this.queryOpt.total = res.content.total;
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
      console.log(res, "获取admin授权管理分页查询");
    },
    //获取新开窗口登录域名
    getLoginDomain() {
      const environment = process.env.VUE_APP_TITLE; //获取当前环境
      const LoginUrlMap = {
        development: "http://demo.emergen.cn:8808",
        // development: "http://localhost:8081",
        test: "https://test.zt.emergen.cn",
        tencent: "https://nk.emergen.cn"
      };
      return LoginUrlMap[environment];
    },
    //发起授权
    async initAuth() {
      await this.getOrgList();
      this.dialogInitVisible = true;
    },
    revoke(row) {
      console.log(row);
      this.handleRow = row;
      this.dialogRevokeVisible = true;
    },
    async sureRevoke() {
      const param = {
        id: this.handleRow.id, //授权管理分页查询出的id，即授权管理子表id
        aahId: this.handleRow.aahId, //授权管理分页查询出的aahId,即授权主表id
        isAuthorize: this.handleRow.isAuthorize, //当前状态
        authorizeOrgId: this.handleRow.authorizeOrgId, //公司id
        authorizeOrgName: this.handleRow.authorizeOrgName, //公司名称
        revokeRemark: this.form.reason, //撤销原因
        authorizeStartTime: this.handleRow.authorizeStartTime, //开始时间
        authorizeEndTime: this.handleRow.authorizeEndTime, //结束时间
        type: 2 //更新类型，2 某用户撤销某公司授权申请, 3某公司同意某用户授权申请,4 某公司拒绝某用户授权申请，5 某公司修改某用户授权时间,6 某公司撤销某用户授权信息
      };
      const res = await updateAuthStatus(param);
      if (res.success) {
        this.dialogRevokeVisible = false;
        this.$message.success("撤销成功");
        this.getauthPagingAdmin();
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
      console.log("确定撤销授权", res);
    },
    //发起授权
    async sureAuth() {
      console.log(this.checkedOrgs);
      const len = this.checkedOrgs.length;
      if (len > 3) {
        return this.$message.warning("已选工厂数不能大于三个!");
      }
      const {
        id: adminId,
        name: adminName,
        mobile: mobilephone
      } = this.loginUser;

      const filter = id => !this.authedOrgData.find(org => org.id === id); // 找出勾选的但是没有授权的 id
      const filterUnAuthOrgs = this.checkedOrgs.filter(filter); //选中的但是没有被授权的工厂
      console.log(this.checkedOrgs, this.authedOrgData, filterUnAuthOrgs);

      //申请企业信息
      const ad = filterUnAuthOrgs.map(v => {
        return {
          authorizeOrgId: v, //勾选的企业id
          authorizeOrgName: this.orgData.find(org => org.id === v).name //勾选的企业名称
        };
      });
      //申请人员信息
      const ah = {
        adminId, //运营登录人员id
        adminName, //运营登录人员名称
        mobilephone //运营登录人员手机号码
      };
      const param = {
        ah,
        ad
      };
      const res = await authAdminAdd(param);
      if (res.success) {
        this.$message.success("发起授权成功");
        this.dialogInitVisible = false;
        this.getauthPagingAdmin();
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
      console.log("发起授权", res);
    },
    //查看日志
    async showLog(row) {
      this.handleRow = row;
      this.logTitle = `${row.authorizeOrgName}授权操作日志`;
      this.dialogLogVisible = true;
      this.getLogList();
    },
    async getLogList() {
      const param = {
        search: {
          orgId: this.handleRow.authorizeOrgId,
          adminid: this.loginUser.id //用户id
        },
        pageIndex: this.logPageSetting.page, // 当前页码
        pageSize: this.logPageSetting.pagesize, // 每页条数
        noCount: false,
        orderBy: "",
        countSql: ""
      };
      const res = await authAdminLog(param);
      if (res.success) {
        this.gridData = res.content.rows;
        this.logPageSetting.total = res.content.total;
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
    },
    changeps(row) {
      this.form = {};
      this.handleRow = row;
      this.dialogFormVisible = true;
    },
    //登录
    login(row) {
      console.log(row);
      this.handleRow = row;
      this.loginForm.account = row.mobilephone;
      this.dialogLoginVisible = true;
    },
    async sureLogin() {
      console.log(this.loginForm);
      const param = {
        AahId: this.handleRow.aahId, //主表id
        AuthorizeOrgId: this.handleRow.authorizeOrgId, // 公司id
        password: this.loginForm.password, // 密码
        Mobilephone: this.loginForm.account //手机号
      };
      const res = await adminLogin(param);
      if (res.success) {
        const token = res.content.token;
        const url = this.getLoginDomain() + `/mes?p_token=${token}&_from=auth`;
        window.open(url, "_blank");
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
      console.log("登录", res);
    },
    search() {},
    //确认修改新密码
    async sureChangePs() {
      if (this.form.newPs !== this.form.ps) {
        return this.$message.warning("两次密码不一致");
      }
      const param = {
        adminid: this.loginUser.id, //用户id//用户id
        AuthorizeOrgId: this.handleRow.authorizeOrgId, //公司id
        // oldpassword: this.form.initPs, // 旧密码
        oldpassword: this.form.initPs, // 旧密码
        newpassword: this.form.newPs //新密码
      };
      const res = await updatePassword(param);
      console.log(res);
      if (res.success) {
        this.dialogFormVisible = false;
        this.getauthPagingAdmin();
        this.$message.success("修改成功");
      } else {
        this.$message.warning(res.msg || res?.exception?.message);
      }
    },
    currentChange(page) {
      this.queryOpt.page = page;
      this.getauthPagingAdmin();
    },
    logPageChange(page) {
      this.getLogList();
    }
  }
};
</script>
<style scoped src="../style.css"></style>
<style lang="less">
.zt-block {
  position: relative;
}
.el-transfer {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-transfer-panel {
    width: 250px;
  }
}
.block-top {
  box-shadow: none;
  background: transparent;
  .bt-item {
    border-left: 4px solid #5074ee;
    background: #fff;
    padding: 20px;
    margin-right: 30px;
    font-size: 14px;
    color: #4d4d4d;
    .left {
      width: 140px;
      border-right: 1px solid #e6e6e6;
      span {
        font-size: 20px;
        color: #292929;
      }
      .left-i {
        margin-bottom: 8px;
        i {
          font-size: 17px;
          color: #5074ee;
          padding: 7px;
          background: #e7ebff;
          border-radius: 50%;
        }
      }
    }
    .right {
      line-height: 30px;
      padding-left: 30px;
      > div {
        color: #b3b3b3;
      }
      label {
        display: inline-block;
        width: 75px;
        text-align: right;
      }
      span {
        font-size: 20px;
        color: #292929;
        margin: 0 4px 0 8px;
      }
    }
  }
  .bt-item:last-child {
    margin-right: 0;
  }
}
</style>
<style scoped>
.tab {
  box-shadow: none;
  background: transparent;
}
.tab_menu .menu_item {
  display: inline-block;
  vertical-align: top;
  width: 86px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background: #e6e6e6;
  margin-right: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur {
  color: #fff;
  background: #5074ee;
}

div >>> .el-table .cell {
  text-align: center;
}
div >>> .el-dialog {
  min-width: 550px;
}
div >>> .el-dialog__header {
  height: 60px;
}
</style>
